<template>
	<div>
		<header-component />
		<b-container>
			<title-component :title="title" :background="background" />

			<div class="content-page border rounded">
				<b-row class="pr-4 pl-4">
					<b-col class="mt-5 mb-5 text-center" md="12">
						<p class="m-0 p-0">
							Tem dúvidas? Manda que a gente te responde rapidinho com o maior
							prazer;)
							<br /><br />
							Se preferir, vc pode vir tomar aquele café esperto e bater um bom
							papo.
							<br /><br />
							<strong>Endereço:</strong><br />
							Av. Deodoro da Fonseca, 420 - Cidade Alta - Natal/RN
							<br /><br />
							<strong>Telefone:</strong><br />
							<a href="tel:+558432214142">+55 84 3221.4142</a><br />
							<a href="tel:+5584996220589">+55 84 99622.0589</a>
							<br /><br />
							<strong>Email:</strong><br />
							<a href="mailto:atendimento@acaocamisetas.com.br">atendimento@acaocamisetas.com.br</a>
						</p>
					</b-col>
				</b-row>
			</div>

		</b-container>
		<footer-component />
	</div>
</template>

<style scoped>
h2 {
	font-size: 20px;
	font-weight: 700;
	text-transform: uppercase;
	text-align: center;
}

.bg-slide {
	min-height: 250px;
	background-size: cover;
	background-position: 50%;
}
</style>

<script>
import Background from "@/images/backgrounds/header-page.png"

import HeaderComponent from "@/components/Header"
import FooterComponent from "@/components/Footer"
import TitleComponent from "@/components/Title"
export default {
	data() {
		return {
			title: "Contato",
			background: Background,
		}
	},
	components: {
		HeaderComponent,
		FooterComponent,
		TitleComponent
	}
}
</script>
